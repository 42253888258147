import React from "react";
import { Link } from "react-router-dom";

import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const Dotcoin = () => {
  const particlesInit = useCallback(async (engine) => {
    

    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <React.Fragment>
      <section>
        <div className="slider-background">
         
      
            <div className="container-flude">
     
              <div className="row canwa-img">
              <Particles
                      id="tsparticles"
                      init={particlesInit}
                      loaded={particlesLoaded}
                      options={{
                    
                        fpsLimit: 60,
                        interactivity: {
                          events: {

                            onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                            resize: true,
                          },
                          modes: {
                            bubble:{
                              distance: 100,
                              duration: 2,
                              opacity:0.8,
                              size:40

                            },
                            push: {
                              quantity: 4,
                            },
                            remove: {
                              particles_nb: 2
                             },
                            repulse: {
                              distance: 100,
                              duration: 2,
                            },
                          },
                        },
                        particles: {
                          color: {
                            value: "#ffffff",
                          },
                          links: {
                            color: "#ffffff",
                            distance: 150,
                            enable: false,
                            opacity: 0.5,
                            width: 1,
                          },
                          collisions: {
                            enable: true,
                          },
                          // move: {
                          //   directions: "none",
                          //   enable: true,
                          //   outModes: {
                          //     default: "bounce",
                          //   },
                            
                          //   random: false,
                          //   speed: 4,
                          //   straight: false,
                          // },
                          move: {
          enable: true, // enabling this will make particles move in the canvas
          speed: { min: 1, max: 2 }, // using a range in speed value will make particles move in a random speed between min/max values, each particles have its own value, it won't change in time by default
        },
                          number: {
                            density: {
                              enable: true,
                              area: 800,
                            },
                            value: 100,
                          },
                          opacity: {
                            value: 0.5,
                          },
                          shape: {
                            type: "circle",
                          },
                          size: {
                            value: { min: 1, max: 3 },
                          },
                        },
                        detectRetina: true,
                      }}
                    />
                <div className="col-sm-6">
                  <div className="slider-contant">
                 
                    <h3>Dotcoin</h3>
                    <p>
                      {" "}
                      Dotcoin works as the Native Token for the DotNFT
                      Marketplace, Intelligent Trading
                      <br /> Bot, Payment Processor, and TheDotExchange. This is
                      a deflationary cryptocurrency
                      <br /> based on the RFI model. The tokenomics has been
                      designed by industry experts in
                      <br /> such a way that its circulation gives profits to
                      the holders. It has a limited and fixed
                      <br />
                      supply. This is the token with a real-life use case. You
                      can purchase it and hold it to
                      <br /> grow your passive income.
                    </p>

                    <Link to="./BuyToken">PURCHASE TOKEN</Link>
                   
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dotcoin-logo1">
                    <img src={`assets/images/coin-logo.png`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </section>
    </React.Fragment>
  );
};

export default Dotcoin;
