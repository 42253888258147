import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from '../src/components/Home/Home'
import TokenWithNav from "./components/content/TokenWithNav";
import DotMarketPlace from "./components/web-pages/DotMarketPlace";
import DotTradingBot from "./components/web-pages/DotTradingBot";
import DotExchange from "./components/web-pages/DotExchange";


function App() {
  return (
    // <BrowserRouter basename='/test-swap'>
    <BrowserRouter basename='/'> 
      <Routes>
      <Route path = '/' element={<Home/>}></Route>
      <Route path = '/BuyToken' element={<TokenWithNav/>}></Route>
      <Route path = '/DotMarketPlace' element={<DotMarketPlace/>}></Route>
      <Route path = '/DotTradingBot' element={<DotTradingBot/>}></Route>
      <Route path = '/DotExchange' element={<DotExchange/>}></Route>
    </Routes>
    </BrowserRouter>
 
  );
}

export default App;
