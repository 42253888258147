import React from 'react'
import { Navbar,Footer } from '../common'
import BuyToken from './BuyToken'

const TokenWithNav = () => {
  return (
    <React.Fragment>
    <Navbar/>
    <BuyToken/>
    <Footer/>
    </React.Fragment>
  )
}

export default TokenWithNav