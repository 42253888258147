import React, { useEffect } from "react"
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';

const Product = () => {

  const options = {
    margin: 30,
    responsiveClass: true,
    // autoHeight: true,
    smartSpeed: 800,
    nav: true,
    dots: false,
    autoplayTimeout: 7000,
    navText: ['<i class="fa fa-angle-left"><img class="arrow2" src=assets/images/slider-arrow11.png></i>', '<i class="fa fa-angle-left"><img class="arrow2" src=assets/images/slider-arrow22.png></i>'],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 2,
      }
    },
  };
  return (
    <React.Fragment>
      <div className="products-page">
        <div className="container-flude">
          <div className="products-heading">
            <h2>Products</h2>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <div className=" ">
                <OwlCarousel className="owl-theme" loop={true} {...options} >
                  <div className="item">
                    <div className="first-service">
                      <div className="sr-img1 text-center">
                      <Link to="/DotMarketPlace" target="_blank"><img src={`assets/images/dotnet.png`} alt="products1" /> </Link>
                      {/* <a href={`assets/static-pages/dot-marketplace.html`} target="_blank"> </a> */}
                      </div>
                      <div className="p-text">
                        <h4 className="products-dot1">DotMarketplace</h4>
                        <p>DotMarketplace is one of the largest and most accessible marketplaces in the crypto art space. Newcomers
                          to the NFT space will easily be able to view thousands of listed NFTs from various artists and exclusive
                          collections across the entire spectrum of the platform.</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="first-service">
                      <div className="sr-img1 text-center">
                        <a href="https://dotpay.thedotverse.com/" target="_blank">
                          <img src={`assets/images/dotpay1.png`} alt="dotpay" />
                        </a>
                      </div>
                      <div className="p-text">
                        <h4 className="products-dot1">TheDotpay</h4>
                        <p>TheDotpay payment processor manages the transactions processed by the merchant and the financial institutions
                          involved in different segments. This payment processor works to ensure that the merchants get paid on time
                          by facilitating the transfer of cryptocurrency funds.</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="first-service">
                      <div className="sr-img1 text-center">
                      <Link to="/DotTradingBot" target="_blank"><img src={`assets/images/dotpay.png`} alt="products-slid1" /> </Link>
                      {/* <a href={`assets/static-pages/dot-trading-bot.html`} target="_blank">
                       <img src={`assets/images/products-slid1.png`} alt="products-slid1" />
                      </a> */}
                      </div>
                      <div className="p-text">
                        <h4 className="products-dot1">Dot Trading Bot</h4>
                        <p>The Intelligent Dot crypto trading bot works to automate your trading experience. You will be able to earn
                          passive income every time even when you sleep. The trading bot supports Customized Trading and swapping between Dapps
                          along with the flash loan feature.</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="first-service">
                      <div className="sr-img1 text-center">
                      <img src={`assets/images/viddly-logo.png`} alt="viddlyapp" />
                      {/* <Link to="/ViddlyApp" target="_blank"><img src={`assets/images/dotpay.png`} alt="viddlyapp" /> </Link> */}
                      {/* <a href={`assets/static-pages/dot-trading-bot.html`} target="_blank">
                       <img src={`assets/images/products-slid1.png`} alt="products-slid1" />
                      </a> */}
                      </div>
                      <div className="p-text">
                        <h4 className="products-dot1">Viddly App</h4>
                        <p> Viddly App, the app that lets you share your content with the world and earn rewards. Viddly is powered by blockchain technology, which means your content is secure, decentralized and immutable!
                        Discover and support other content creators on the platform, and join communities of like-minded people
                        </p>
                       
                              <a href="https://firebasestorage.googleapis.com/v0/b/viddly-nft.appspot.com/o/app-release.apk?alt=media&token=6f22ac31-4c2d-4b70-8094-1feedf136d76">Download the Viddly App</a>
                      </div>
                      
                    </div>
                  </div>
                  <div className="item">
                    <div className="first-service">
                      <div className="sr-img1 text-center">
                      <Link to="/DotExchange" target="_blank"><img src={`assets/images/dot-exchang.png`} alt="dotexchange" /> </Link>
                      {/* <a href={`assets/static-pages/dot-exchange.html`} target="_blank">
                      <img src={`assets/images/dotexchange.jpg`} alt="dotexchange" />
                      </a> */}
                      </div>
                      <div className="p-text">
                        <h4 className="products-dot1">DotExchange</h4>
                        <p>DotExchange is an online exchange where users can trade cryptocurrencies. It supports the most commonly traded
                          cryptocurrencies. The Exchange provides a crypto wallet for the traders to store their electronic funds.
                          The exchange will also support services for users to earn interest and transact cryptocurrencies. More
                          features will be introduced in the next versions.</p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}

export default Product

