import React from "react";
import { useState,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import WalletConnectProvider from "@walletconnect/web3-provider";
// import getWalletProvider from '../../utils/getWalletProvider';
// import Web3 from "web3";
// import { ethers } from 'ethers';

const Navbar = () => {
  const location = useLocation();
  const [defaultAccount, SetDefaultAccount] = useState(null);
  const [userBalance, SetUserBalance] = useState(null);
  const [conButtonText, SetConButtonText] = useState("Connect Wallet");
  const [errorMessage, SetErrorMessage] = useState(null);
  const walletType = process.env.REACT_APP_WALLET_TYPE_METAMASK
  
  useEffect(()=>{
    if(localStorage.getItem("wallet")){
      SetConButtonText("Disconnect")
    }else{
      SetConButtonText("Connect Wallet")
    }
    accountChangeHandler();
 },[]);

  const ConnectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          localStorage.setItem("wallet", result[0]);
          localStorage.setItem("wallet_type", walletType); 
          window.location.reload();
      });
    } else {
      console.log("i m in else condition")
      connect();
    }
  };

 const updateDimensions = () => {
    if (window.innerWidth < 768) {
      var element = document.getElementsByClassName(
        "walletconnect-modal__mobile__toggle"
      );

      if (element.length > 0) {
        element[0].lastChild.click();
      }
    }
  };

 const connect = async () => {
    setTimeout(updateDimensions, 100);
    try {
      const paramWC = {
        rpc: {
          [process.env.REACT_APP_BLOCKCHAIN_ID]: `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`,
        },
        mobileLinks: [],
      };
      const provider = new WalletConnectProvider(paramWC);
      const response = await provider.enable();
      localStorage.setItem("wallet_type", `${process.env.REACT_APP_WALLET_TYPE_WALLET_CONNECT}`); 
      localStorage.setItem("wallet", response[0]);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };
  
  const accountChangeHandler = (account) => {  
    SetDefaultAccount(account);
  };

  const DisConnect = (e) =>{
    localStorage.setItem("wallet", "");
    localStorage.setItem("wallet_type", ""); 
    SetConButtonText("Connect Wallet");
    window.location.reload();
  }
  
 return (
    <React.Fragment>
      <div className="dotcoin-navbar">
        <div className="container-flude">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
              <div className="nav-title">
              <Link to="/"><img src={`assets/images/logo1.png`}  alt="logo1" /></Link>
              {/* <a href="https://dotcoin.thedotverse.com/" >
                  <img src={`assets/images/logo1.png`}  alt="logo1" />
                </a> */}
              </div>
            </div>
            <div className="nav-btn">
              <label htmlFor="nav-check">
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className="nav-links">
              <a href="https://t.me/+VfXDWm3zsagyMDVl" target="_blank">
                JOIN TELEGRAM
              </a>
               
               
              <a href="https://discord.gg/4enNGp9DsB" target="_blank">
                JOIN DISCORD
              </a>
            {location.pathname === "/"? <a href="#roadmaps">ROADMAP</a>:"" }
           
              <a href={`assets/images/Whitepaper.pdf`} target="_blank">
                WHITEPAPER
              </a>
              
             {conButtonText === "Connect Wallet" ? <a className="walletButtonCurssor" onClick={ConnectWalletHandler } >
                {conButtonText}
              </a>: <a  className="walletButtonCurssor" onClick={DisConnect} >
                {conButtonText}
              </a> }

            
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
