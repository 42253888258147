import React from "react"

const Roadmap = () => {
  return (
<React.Fragment>
  <section id="roadmaps">
    <div className="loaction-page">
      <div className="container-flude">
        <h2>Road Map</h2>
        <ul className="timeline list-unstyled">
          {/* History Year */}
          <li className="year"><img src={`assets/images/locator.png`} alt="locator" /> </li>
          <li className="note animated fadeInRight visible road1" data-animation="fadeInRight" data-animation-delay={50}>
            <h4>
              DotCoin Launch
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInLeft visible road2" data-animation="fadeInLeft" data-animation-delay={50}>
            <h4>
              Quarter 3, 2022
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInRight visible road3" data-animation="fadeInRight" data-animation-delay={100}>
            <h4>
              Quarter 4, 2022 </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInLeft visible road4" data-animation="fadeInLeft" data-animation-delay={100}>
            <h4>
              DotPay Payment Processor
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          {/* History Year */}
          <li className="year"><img src={`assets/images/locator.png`} alt="locator.png" /></li>
          <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
            <h4>
              Quarter 1, 2023
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
            <h4>
              Dot NFT Marketplace Launch
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInRight visible road7" data-animation="fadeInRight" data-animation-delay={150}>
            <h4>
              Dot Trading Bot Launch
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInRight visible road8" data-animation="fadeInRight" data-animation-delay={150}>
            <h4>
              Quarter 2, 2023
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
            <h4>
              Quarter 3, 2023
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
            <h4>
              DotExchange Launch
            </h4>
            <span className="arrow fa fa-play" />
          </li>
          {/* Start icon */}
          <li className="year last"><img src={`assets/images/locator.png`} alt="locator" /></li>
          <li className="clear" />
        </ul>
      </div>
    </div>
  </section>
</React.Fragment>

  )
}

export default Roadmap