import React from "react";
import { useState, useEffect } from "react";
import getWalletProvider from "../../utils/getWalletProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as CONTRACTS from "../contract";
import { ethers } from "ethers";
import getUTCString from "../../utils/getUTCString";
import Web3 from "web3";

const BuyToken = () => {
  const [amountIn, setAmountIn] = useState("");
  const [amountOut, setAmountOut] = useState("");
  const [amountInError, setAmountInError] = useState("");
  const [amountOutError, setAmountOutError] = useState("");
  const [price, setPrice] = useState(0);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [balanceBNB, setBalanceBNB] = useState(0);
  const [fetchBalance, setFetchBalance] = useState(true);
  const [toggleButton, setToggleButton] = useState(true);
  const [amountSellIn, setAmountSellIn] = useState("");
  const [amountSellOut, setAmountSellOut] = useState("");

  const address = process.env.REACT_APP_DOTCOIN_ADDRESS;
  const walletType = localStorage.getItem("wallet_type");
  const walletAddress = localStorage.getItem("wallet");
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const length = 8;
  const BLOCKCHAIN_ID = process.env.REACT_APP_BLOCKCHAIN_ID;

  useEffect(() => {
  
    onAccountChange();
    fetchDCNCurrentPrice();
    getBalance(walletAddress);
    
  }, []);
  
  const fetchDCNCurrentPrice = async () => {
    try{

      if (window.ethereum && window.ethereum.isMetaMask) {
        const web3 = new Web3(window.ethereum);
        var DcnContract = new web3.eth.Contract(CONTRACTS.Dot.abi, address);
        var receipt = await DcnContract.methods.dotCoinPrice().call();
        const pricesConvert = ethers.utils.formatUnits(receipt, "ether");
        setPrice(parseFloat(pricesConvert));
      }
      else{
        const web3 = new Web3(new Web3.providers.HttpProvider(`${BLOCKCHAIN_NETWORK}`));
        var DcnContract = new web3.eth.Contract(CONTRACTS.Dot.abi, address);
        var receipt = await DcnContract.methods.dotCoinPrice().call();
        const pricesConvert = ethers.utils.formatUnits(receipt, "ether");
        setPrice(parseFloat(pricesConvert));
      }

    }
    catch(err){
    
      toast.error('Please Connect BSC  Network!', {
        position: toast.POSITION.TOP_CENTER,
      });   
   
    }
   
  }

  const onAccountChange = () => {
    if (window.ethereum !== undefined) {
      window.ethereum.on("networkChanged", function (networkId) {
        // ConnectedNetworkID = networkId;
        if (networkId !== BLOCKCHAIN_ID) {
          toast.error("Unsupported Chain Id Error. Please Connect BSC  Network!", {
            position: toast.POSITION.TOP_CENTER,
          });
         
        } else {
          window.location.reload();
        }
      });
    }
  };

  const getBalance = async (account) => {
    const walletAddress = localStorage.getItem("wallet");
    const { ethereum } = window;
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        web3.eth.getBalance(walletAddress, (err, bal) => {
          if (err) {
            return console.log(err);
          }
          setBalanceBNB(ethers.utils.formatUnits(bal.toString(), "ether"));
        });
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(
            `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`
          )
        );
        web3.eth.getBalance(account, (err, bal) => {
          if (err) {
            return console.log(err);
          }
          setBalanceBNB(ethers.utils.formatUnits(bal.toString(), "ether"));
        });
      } catch (e) {
      
        console.log(e)
      }
    }
  };

  const handleAmountIn = (e) => {
    if (e.target.value !== "") {
      const regex = /\D/;
      if(e.target.value.length==11) return false;   //limits to 10 digit entry

      if (isNaN(e.target.value)) {
        setAmountInError("Only digits are allowed");
        return;
      } else if (parseFloat(e.target.value) < 0) {
        setAmountInError("Amount should be more than zero");
        return;
      } else {
        setAmountInError("");
      }

      const _amountIn = parseFloat(e.target.value);
      const _amountOut = (_amountIn / price) * 0.92;
     
     
       setAmountIn(e.target.value);
      setAmountOut(_amountOut);
      setAmountOutError("");
    } else {
      setAmountInError("Amount in is required");
      setAmountIn("");
      setAmountOut("");
    }
  };
  const handleAmountSellIn = (e) => {
    if (e.target.value !== "") {
      const regex = /\D/;
      if(e.target.value.length==11) return false;   //limits to 10 digit entry
      if (isNaN(e.target.value)) {
        setAmountInError("Only digits are allowed");
        return;
      } else if (parseFloat(e.target.value) < 0) {
        setAmountInError("Amount should be more than zero");
        return;
      } else {
        setAmountInError("");
      }
      const _amountSellIn = parseFloat(e.target.value);
      const _amountSellOut = _amountSellIn * price;
     setAmountSellIn(e.target.value);
      setAmountSellOut(_amountSellOut);
      setAmountOutError("");
    } else {
      setAmountInError("Amount in is required");
      setAmountSellIn("");
      setAmountSellOut("");
    }
  };

  const swapTokens = async (e) => {
    e.preventDefault();
    const walletType = localStorage.getItem("wallet_type");
    const walletAddress = localStorage.getItem("wallet");

    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let error = false;
    if (amountIn === "") {
      toast.error("Amount in is required!", {
        position: toast.POSITION.TOP_CENTER,
      });
      error = true;
    }
    if (error) {
      return;
    }

    if (amountIn === "" || amountIn <= 0) {
      toast.error("In amount is required and should be more than zero", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const _amountIn = parseFloat(amountIn);

    if (_amountIn > parseFloat(balanceBNB)) {
      setError("Insufficient BNB in wallet");
      toast.error("Insufficient BNB in wallet", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (amountOut <= 0) {
      toast.error("Out amount is required and should be more than zero", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      setLoading(true);
      const d1 = new Date();

      const currentTime = Math.floor(
        new Date(getUTCString(d1)).getTime() / 1000
      );
      let transaction;
      const provider = await getWalletProvider();
      const signer = provider.getSigner();
      const bnbprice = ethers.utils.parseUnits(amountIn.toString(), "ether");
      const dotContract = new ethers.Contract(
        process.env.REACT_APP_DOTCOIN_ADDRESS,
        CONTRACTS.Dot.abi,
        signer
      );
      if (toggleButton) {
        transaction = await dotContract.buyDotCoin({ value: bnbprice });
      } else {
        let outAmount = Web3.utils.toWei(amountOut, "ether");
        transaction = await dotContract.sellDotCoin(outAmount);
      }

      await transaction.wait();
      toast.success("Transaction successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
       fetchDCNCurrentPrice();
      getBalance(walletAddress);
      
      setAmountIn("");
      setAmountOut("");
      setAmountInError("");
      setAmountOutError("");
      setLoading(false);
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        toast.error("User Rejected The Transaction", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      setLoading(false);
    }
  };

  const onToggleClick = () => {
    if (toggleButton) {
      setToggleButton(false);
    } else {
      setToggleButton(true);
    }
  };

  const buyTokens = async (e) => {
    e.preventDefault();

    const walletType = localStorage.getItem("wallet_type");
    const walletAddress = localStorage.getItem("wallet");

    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let error = false;
    if (amountSellIn === "") {
      toast.error("Amount in is required!", {
        position: toast.POSITION.TOP_CENTER,
      });
      error = true;
    }

    if (error) {
      return;
    }

    if (amountSellIn === "" || amountSellIn <= 0) {
      toast.error("In amount is required and should be more than zero", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const _amountSellIn = parseFloat(amountSellIn);
    // if (_amountSellIn > parseFloat(balanceBNB)) {
    //   setError("Insufficient DCN in wallet");
    //   toast.error("Insufficient DCN in wallet", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }
    if (amountSellOut <= 0) {
      toast.error("Out amount is required and should be more than zero", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
  
    try {
      setLoading(true);
      let transaction;
      const provider = await getWalletProvider();
      const signer = provider.getSigner();
      const dotContract = new ethers.Contract(
        process.env.REACT_APP_DOTCOIN_ADDRESS,
        CONTRACTS.Dot.abi,
        signer
      );
      let outAmount = Web3.utils.toWei(amountSellIn, "ether");
      transaction = await dotContract.sellDotCoin(outAmount);
      await transaction.wait();

      toast.success("Transaction successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      fetchDCNCurrentPrice();
      getBalance(walletAddress);
      setAmountSellIn("");
      setAmountSellOut("");
      setAmountInError("");
      setAmountOutError("");
      setLoading(false);
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        toast.error("User Rejected The Transaction", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      else{
        toast.error("You don`t have required amount of tokens", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      setLoading(false);
    }
  
  };

  return (
    <div className="row nft-swap no-gutters">
      {loading ? (
        <div className="loadingImg">
          <center>
            <p className="color-white">
              Please wait while transaction completed...
            </p>
          </center>
        </div>
      ) : (
        <React.Fragment>
          <div>
            <div className="coin-by-banner">
              <div className="container-flude">
                <h2>PURCHASE TOKEN </h2>
              </div>
            </div>
  

            {toggleButton ? (
              <div className="chart-page buy">
                <div className="container"> 
                  <div className="row">
                 
                    <div className="col-lg-5 col-md-12 col-sm-12">
                    <div className="Swap">
                        <div className="Swap-one">
                          <h4>Swap</h4>
                          <ul>
                            {/* <li className="swap-head">Swap</li> */}
                          </ul>
                          {/* <p>Trade tokens in an instant</p> */}
                        </div>

                        <div className="bnb">
                          <div className="balance">
                            <h4>
                              <span style={{ marginRight: 5 }}>
                                <img src={`assets/images/bnb-icon1.png`} />
                              </span>
                              BNB
                              <span style={{ marginLeft: 5 }}>
                        
                              </span>
                            </h4>
                            <div className="panswap-bnb-balance">
                              <label>
                                Balance: {parseFloat(balanceBNB).toFixed(6)} BNB
                              </label>
                            </div>
                          </div>
                          <div className="input-field">
                            <div className="field1">
                              <input
                                type="number"
                                placeholder={0.0}
                                id="Bnb"
                                className="email"
                                value={amountIn}
                                
                                onChange={handleAmountIn} autoComplete="off"
                           
                              />
                              <span className="red-error">{amountInError}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc-de7e8801-1 sc-a99b64d2-0 sc-a99b64d2-3 dUUCVU eHQTRo biGuAW toggal1"
                          style={{ padding: 0 }}
                        >
                          <button
                            className="sc-ee37452c-0 cBCXMC sc-6498c20f-0 sc-37fe25ee-1 jodCJW htdPuR"
                            scale="sm"
                            onClick={onToggleClick}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              className="sc-8a800401-0 wNVai icon-down"
                              color="primary"
                              width="20px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11 5V16.17L6.11997 11.29C5.72997 10.9 5.08997 10.9 4.69997 11.29C4.30997 11.68 4.30997 12.31 4.69997 12.7L11.29 19.29C11.68 19.68 12.31 19.68 12.7 19.29L19.29 12.7C19.68 12.31 19.68 11.68 19.29 11.29C18.9 10.9 18.27 10.9 17.88 11.29L13 16.17V5C13 4.45 12.55 4 12 4C11.45 4 11 4.45 11 5Z"></path>
                            </svg>
                           
                          
                          </button>
                        </div>
                        <div className="bnb second">
                          <h4>
                            <span style={{ marginRight: 5 }}>
                              <img src={`assets/images/bnb-icon1.png`} />
                            </span>
                            DCN
                            <span style={{ marginLeft: 5 }}>
                            </span>
                          </h4>
                          <div className="input-field">
                            <input
                              type="number"
                              placeholder={0.0}
                              id="Dcn"
                              value={amountOut}
                              className="email"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          {price > 0 && (
                            <div className="price-label">
                              <span><h5>Price</h5> </span>
                              <div className="price-label-dcn">
                                <strong>
                                  {parseFloat(price).toFixed(8)} BNB per DCN
                                </strong>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={swapTokens}
                            className="Connect-button"
                          >
                            Swap
                          </button>
                          <ToastContainer />
                   
                        </div>
                      </div>
                     
                    </div>
                    
                    <div className="col-lg-7 col-md-12 col-sm-12">
                       {/* <div className="chart-img">
                        <img src={`assets/images/chart-img.jpg`} />
                      </div> */}
                      <div className="chart-img">
                          {/* <video poster="" controls className="dog-park-video">
                          <source src="https://youtu.be/JEXuxEGzYgI" type="video/mp4" />  
                           </video> */}
                           <iframe  className="dog-park-video" src="https://www.youtube.com/embed/PQ3zm-LelYI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                     

                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="chart-page sell">
                <div className="container">
                  <div className="row">
               
                    <div className="col-lg-5 col-md-12 col-sm-12">
                     <div className="Swap">
                        <div className="Swap-one">
                          <h4>Swap</h4>
                          <ul>
                            {/* <li className="swap-head">Buy</li> */}
                          </ul>
                          {/* <p>Trade tokens in an instant</p> */}
                        </div>

                        <div className="bnb">
                          <div className="balance">
                            <h4>
                              <span style={{ marginRight: 5 }}>
                                <img src={`assets/images/bnb-icon1.png`} />
                              </span>
                              DCN
                              <span style={{ marginLeft: 5 }}>
                              </span>
                            </h4>
                            <div className="panswap-bnb-balance">
                              <label>
                                Balance: {parseFloat(balanceBNB).toFixed(6)} BNB
                              </label>
                            </div>
                          </div>

                          <div className="input-field">
                            <input
                              type="number"
                              placeholder={0.0}
                              id="Dcn"
                              value={amountSellIn}
                              className="email"
                              onChange={handleAmountSellIn}
                            />
                            <span className="red-error">{amountInError}</span>
                          </div>
                        </div>

                        <div
                          className="sc-de7e8801-1 sc-a99b64d2-0 sc-a99b64d2-3 dUUCVU eHQTRo biGuAW toggal1"
                          style={{ padding: 0 }}
                        >
                          <button
                            className="sc-ee37452c-0 cBCXMC sc-6498c20f-0 sc-37fe25ee-1 jodCJW htdPuR"
                            scale="sm"
                            onClick={onToggleClick}
                          >
              
                            <svg
                              viewBox="0 0 24 24"
                              className="sc-8a800401-0 wNVai icon-up-down"
                              color="primary"
                              width="20px"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M16 17.01V11c0-.55-.45-1-1-1s-1 .45-1 1v6.01h-1.79c-.45 0-.67.54-.35.85l2.79 2.78c.2.19.51.19.71 0l2.79-2.78c.32-.31.09-.85-.35-.85H16zM8.65 3.35L5.86 6.14c-.32.31-.1.85.35.85H8V13c0 .55.45 1 1 1s1-.45 1-1V6.99h1.79c.45 0 .67-.54.35-.85L9.35 3.35a.501.501 0 00-.7 0z"></path>
                            </svg>
                          </button>
                        </div>

                        <div className="bnb">
                          <h4>
                            <span style={{ marginRight: 5 }}>
                              <img src={`assets/images/bnb-icon1.png`} /> 
                            </span>
                            BNB
                            <span style={{ marginLeft: 5 }}>
                        
                            </span>
                          </h4>
                          <div className="input-field">
                            <div className="field1">
                              <input
                                type="number"
                                placeholder={0.0}
                                id="Bnb"
                                className="email"
                                value={amountSellOut}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mb-4">
                          {price > 0 && (
                            <div className="price-label">
                              <span><h5>Price</h5> </span>
                              <div className="price-label-dcn">
                                <strong>
                                  {parseFloat(price).toFixed(8)} BNB per DCN
                                </strong>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <button
                            type="button"
                            onClick={buyTokens}
                            className="Connect-button"
                          >
                            Swap
                          </button>
                          <ToastContainer />
                        </div>
                      </div>
                   
                    </div>
                    
                    <div className="col-lg-7 col-md-12 col-sm-12">
                    <div className="chart-img">
                    {/* <iframe className="dog-park-video"
                           src="https://www.youtube.com/embed/watch?v=c0L58KlsWfA">
                    </iframe>  */}
                    <iframe  className="dog-park-video" src="https://www.youtube.com/embed/PQ3zm-LelYI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* </form> */}
          </div>
        </React.Fragment>
      )}
    </div>
);
         } ;

export default BuyToken;
