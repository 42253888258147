import React from "react";
import { Link } from 'react-router-dom';

const DotMarketPlace = () => {

  return (
    <React.Fragment>
      <div className="dotcoin-navbar dotmarket">
        <div className="container-flude">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
              <div className="nav-title">
              {/* <Link to="/"><img src={`assets/images/logo-dotnft.png`} alt="logo1" /></Link> */}
                <img src={`assets/images/logo-dotnft.png`} alt="logo1" />
              </div>
            </div>
            <div className="nav-btn">
            <label htmlFor="nav-check">
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className="nav-links">
              <a href="https://t.me/+VfXDWm3zsagyMDVl" target="_blank">
                JOIN TELEGRAM
              </a>

              <a href="https://discord.gg/4enNGp9DsB" target="_blank">
                JOIN DISCORD
              </a>

              <a href="#roadmaps-market">ROADMAP</a>

              <a href={`assets/images/Whitepaper.pdf`} target="_blank">
                WHITEPAPER
              </a>

            </div>
          </div>
        </div>
      </div>
      
      <div>
        <div className="dot-bg market">
          <div className="container-flude">
            <h2>Dot-MarketPlace</h2>
            <p>
              One of the most innovative digital marketplace for crypto collectibles and non-fungible tokens (NFTs). NFT marketplace with an appealing and instinctive presentation that rundowns various NFTs like advanced expressions, music, in-game things, exchanging cards, and so forth
              Buy, sell, &amp; explore exclusive digital collectibles.
            </p>
          </div>
        </div>
        {/* slider-background end */}
        <div className="service__area pt-115 pb-90 p-relative">
          <div className="service__shape-1 d-none d-xl-block">
            <img src={`assets/images/dot-market-one.png`} alt="" />
          </div>
          <div className="service__shape-2 d-none d-xl-block">
            <img src={`assets/images/dot-market-tow.png`} alt="" />
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9">
                <div className="service__section-box text-center market-feature mb-50">
                  <h4 className="section-subtitle">Dot-MarketPlace Features</h4>
                  {/* <h2 class="section-title">
                    One of the most innovative digital marketplace for crypto collectibles and non-fungible tokens (NFTs). NFT marketplace with an appealing and instinctive presentation that rundowns various NFTs like advanced expressions, music, in-game things, exchanging cards, and so forth
                    Buy, sell, & explore exclusive digital collectibles.
                  </h2> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg market-dot1 text-center">
                  <div className="service__top-icon">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm">
                        <a>
                          Minting  <br></br>then Listing
                        </a>
                        </h4>
                      <p>NFT Marketplace guarantees that the maker will have an issue-free encounter while posting and stamping their valuable work as a Non-fungible token.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg market-dot1 text-center">
                  <div className="service__top-icon">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm">
                        <a>
                          Listing <br></br>Status
                        </a>
                        </h4>
                      <p>NFT Marketplace will give all the important data to makers, for example, the clients who saw their NFTs and the number of individuals who have made the offering once listed.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg market-dot1 text-center">
                  <div className="service__top-icon">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm">
                        <a>
                          Digital  <br></br>Assets Auctions
                        </a>
                          </h4>
                      <p>NFT marketplace permits clients to purchase resources by taking an interest in the closeout. Since the offering system is frequently extraordinary, the stage gives constant data to all members.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg market-dot1 text-center">
                  <div className="service__top-icon">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm">
                        <a>
                          NFT  <br></br>Wallet
                        </a>
                        </h4>
                      <p>The primary feature of the stage is an NFT wallet that empowers clients to make consistent transactions to send, get and store NFTs. You name the kind of wallet and we will integrate it for you.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg market-dot1 text-center">
                  <div className="service__top-icon">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm">
                        <a>
                          Explore  <br></br>Different NFTs
                        </a>
                        </h4>
                      <p>NFT Marketplace is inserted with cutting-edge search options, where purchasers can investigate a large number of NFTs and observe the specific advanced resources they need to offer.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* loaction-page start */}
        <section id="roadmaps-market">
          <div className="loaction-page">
            <div className="container-flude">
              <h2>Road Map</h2>
              <ul className="timeline list-unstyled">
                {/* History Year */}
                <li className="year"><img src={`assets/images/locator.png`} /> </li>
                <li className="note animated fadeInRight visible road1" data-animation="fadeInRight" data-animation-delay={50}>
                  <h4>
                    DotCoin Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road2" data-animation="fadeInLeft" data-animation-delay={50}>
                  <h4>
                    Quarter 3, 2022
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road3" data-animation="fadeInRight" data-animation-delay={100}>
                  <h4>
                    Quarter 4, 2022 </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road4" data-animation="fadeInLeft" data-animation-delay={100}>
                  <h4>
                    DotPay Payment Processor
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                {/* History Year */}
                <li className="year"><img src={`assets/images/locator.png`} /></li>
                <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Quarter 1, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Dot NFT Marketplace Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road7" data-animation="fadeInRight" data-animation-delay={150}>
                  <h4>
                    Dot Trading Bot Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road8" data-animation="fadeInRight" data-animation-delay={150}>
                  <h4>
                    Quarter 2, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Quarter 3, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    DotExchange Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                {/* Start icon */}
                <li className="year last"><img src={`assets/images/locator.png`} /></li>
                <li className="clear" />
              </ul>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default DotMarketPlace;
