import React from "react";
import  Nav  from '../web-pages/Nav';
import { Link } from 'react-router-dom';

const DotTradingBot = () => {
  return (
    <React.Fragment>
      <div className="dotcoin-navbar dotmarket">
        <div className="container-flude">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
              <div className="nav-title">
                <img src={`assets/images/logo-Bot.png`} alt="logo1" />
                {/* <Link to="/"><img src={`assets/images/logo-Bot.png`} alt="logo1" /></Link> */}
              </div>
            </div>
            <div className="nav-btn">
            <label htmlFor="nav-check">
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className="nav-links">
              <a href="https://t.me/+VfXDWm3zsagyMDVl" target="_blank">
                JOIN TELEGRAM
              </a>

              <a href="https://discord.gg/4enNGp9DsB" target="_blank">
                JOIN DISCORD
              </a>

              <a href="#roadmaps-bot">ROADMAP</a>

              <a href={`assets/images/Whitepaper.pdf`} target="_blank">
                WHITEPAPER
              </a>

            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="dot-bg tradingBot">
          <div className="container-flude">
            <h2>Dot-Trading Bot</h2>
            <p>
            The trading bot that allows much customization to the users and helps you to get the most suitable level of trading. Kill Volatility by purchasing low and selling high to create an investment portfolio that spreads risks in long term.
            </p>
          </div>
        </div>
        {/* slider-background end */}
        <div className="service__area pt-115 pb-90 p-relative">
          <div className="service__shape-1 d-none d-xl-block">
            <img src={`assets/images/dot-bot1.png`} alt="" />
          </div>
          <div className="service__shape-2 d-none d-xl-block">
            <img src={`assets/images/dot-bot2.png`} alt="" />
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9">
                <div className="service__section-box text-center mb-50 dotbot-feature">
                  <h4 className="section-subtitle">Dot-Trading Bot Features</h4>
                  {/* <h2 class="section-title">
                    One of the most innovative digital marketplace for crypto collectibles and non-fungible tokens (NFTs). NFT marketplace with an appealing and instinctive presentation that rundowns various NFTs like advanced expressions, music, in-game things, exchanging cards, and so forth
                    Buy, sell, & explore exclusive digital collectibles.
                  </h2> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg dotbot text-center">
                  <div className="service__top-icon dotbot1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Manage your portfolio</a></h4>
                      <p>Associate all your exchanges accounts and manage them with our trading terminal. Follow the price movement and trade consequently when the price goes in another direction.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg dotbot text-center">
                  <div className="service__top-icon dotbot1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Create Automated Trading Rules</a></h4>
                      <p>Set custom automated trades and never miss a rally or get found out in a plunge. This bot gives your powerful market indicators to enable smart allocation of assets.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
             
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg dotbot text-center">
                  <div className="service__top-icon dotbot1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Advanced Security</a></h4>
                      <p>Dot-Trading Bot is a beginner-friendly and safe platform to send automated trading instructions to your favourite exchanges. We don't request private keys or withdrawal rights.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg dotbot text-center">
                  <div className="service__top-icon dotbot1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Customizations</a></h4>
                      <p>Customizations with different strategies like scalping, back testing, and arbitrages. Multiple order types, exchanges, and order templates.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg dotbot text-center">
                  <div className="service__top-icon dotbot1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Tried & Tested</a></h4>
                      <p>A tried & tested Trading Bot for digital assets trading. It can help you compete efficiently and successfully.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* loaction-page start */}
        <section id="roadmaps-bot">
          <div className="loaction-page">
            <div className="container-flude">
              <h2>Road Map</h2>
              <ul className="timeline list-unstyled">
                {/* History Year */}
                <li className="year"><img src={`assets/images/locator.png`} /> </li>
                <li className="note animated fadeInRight visible road1" data-animation="fadeInRight" data-animation-delay={50}>
                  <h4>
                    DotCoin Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road2" data-animation="fadeInLeft" data-animation-delay={50}>
                  <h4>
                    Quarter 3, 2022
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road3" data-animation="fadeInRight" data-animation-delay={100}>
                  <h4>
                    Quarter 4, 2022 </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road4" data-animation="fadeInLeft" data-animation-delay={100}>
                  <h4>
                    DotPay Payment Processor
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                {/* History Year */}
                <li className="year"><img src={`assets/images/locator.png`} /></li>
                <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Quarter 1, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Dot NFT Marketplace Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road7" data-animation="fadeInRight" data-animation-delay={150}>
                  <h4>
                    Dot Trading Bot Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road8" data-animation="fadeInRight" data-animation-delay={150}>
                  <h4>
                    Quarter 2, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Quarter 3, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    DotExchange Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                {/* Start icon */}
                <li className="year last"><img src={`assets/images/locator.png`} /></li>
                <li className="clear" />
              </ul>
            </div>
          </div>
        </section>
      </div>

    </React.Fragment>
  );
};

export default DotTradingBot;
