import React from 'react'

const HowToPurchase = () => {
  return (
    <React.Fragment>
  <section>
    <div className="purchase">
      <div className="content-103">
        <div className="container">
          <h2 className="sercies-title">How To Purchase</h2>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-3">
              <a className="all-page-link" href="https://support.binance.us/hc/en-us/articles/4423865255319-Configuring-MetaMask-with-Binance-Smart-Chain" target="_blank">
                <div className="service-post">
                  <div className="service-content">
                    <div className="service-icon">
                      <img src={`assets/images/purchase-icon1.jpg`} alt="purchase-icon1" />
                    </div>
                    
                    <h3 className="service-title">Create a Metamask 
                      wallet</h3>
                  </div>
           
                  <div className="service-hover" />
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-3">
              <a className="all-page-link" href="https://www.binance.com/en/how-to-buy/bnb" target="_blank">
                <div className="service-post">
                  <div className="service-content">
                    <div className="service-icon">
                      <img src={`assets/images/purchase-icon3.jpg`} alt="purchase-icon2" />
                    </div>
            
                    <h3 className="service-title">Buy BNB</h3>
                  </div>
             
                  <div className="service-hover" />
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-3">
              <a className="all-page-link" href="https://crypto-explained.com/services/send-bnb-coin-to-binance-smart-chain-on-metamask/" target="_blank">
                <div className="service-post">
                  <div className="service-content">
                    <div className="service-icon">
                      <img src={`assets/images/purchase-icon3.jpg`} alt="purchase-icon3" />
                    </div>
                 
                    <h3 className="service-title">Transfer BNB to 
                      Metamask</h3>
                  </div>
             
                  <div className="service-hover" />
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-3">
              <a className="all-page-link" href="https://pancakeswap.finance/swap" target="_blank">
                <div className="service-post">
                  <div className="service-content">
                    <div className="service-icon">
                      <img src={`assets/images/purchase-icon4.jpg`} alt="purchase-icon4" />
                    </div>
           
                    <h3 className="service-title">Buy from Pancak..
                      eswap</h3>
                  </div>
  
                  <div className="service-hover" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</React.Fragment>

  )
}

export default HowToPurchase