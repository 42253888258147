import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
   <React.Fragment>
  <section>
  <div className="social-links">
    <div className="container-flude">
      <div className="col-lg-12">
        <div className="social-links1">
          <h4>Social Links</h4>
          <ul>
            <li><a href="https://www.facebook.com/Dotcoin-110226251772069" target="_blank"><img src={`assets/images/fb-icon1.png`} alt="fb-icon1" /></a></li>
            <li><a href="https://twitter.com/DotCoin6" target="_blank"><img src={`assets/images/tiwter-icon1.png`} alt="tiwter-icon1" /></a></li>
            <li><a href="https://www.instagram.com/dotcoin26/" target="_blank"><img src={`assets/images/insta-icon1.png`}  alt="insta-icon1" /></a></li>
            <li><a href="https://www.linkedin.com/showcase/dotcoin/about"><img src={`assets/images/linkdin-icon1.png`} alt="linkdin-icon1" /></a></li>
            <li><a href="https://thedotverse.medium.com/" target="_blank"><img src={`assets/images/medium.png`}  alt="medium" /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

   </React.Fragment>
  )
}

export default Footer