import React from "react";
import { Link } from 'react-router-dom';

const DotExchange = () => {

  return (
    <React.Fragment>
      <div className="dotcoin-navbar dotmarket">
        <div className="container-flude">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
              <div className="nav-title">
                {/* <Link to="/"><img src={`assets/images/logo1.png`} alt="logo1" /></Link> */}
                  <img src={`assets/images/logo-dotexch.png`} alt="logo1" />
              </div>
              
            </div>
            <div className="nav-btn">
              {/* <label htmlFor="nav-check"></label> */}
              <label htmlFor="nav-check">
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className="nav-links">
              <a href="https://t.me/+VfXDWm3zsagyMDVl" target="_blank">
                JOIN TELEGRAM
              </a>

              <a href="https://discord.gg/4enNGp9DsB" target="_blank">
                JOIN DISCORD
              </a>

              <a href="#roadmaps-exchange">ROADMAP</a>

              <a href={`assets/images/Whitepaper.pdf`} target="_blank">
                WHITEPAPER
              </a>

            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="dot-bg exchange">
          <div className="container-flude">
            <h2>Dot-Exchange</h2>
            <p>
            One of the Top Crypto Exchange
            Trade promising crypto coins on one of the most trusted crypto exchanges. You can interact with global crypto investors and get access to regular trusted crypto announcements. The Dot-Exchange platform allows you to start crypto trading with no extra effort.
            </p>
          </div>
        </div>
        {/* slider-background end */}
        <div className="service__area pt-115 pb-90 p-relative">
          <div className="service__shape-1 d-none d-xl-block">
            <img src={`assets/images/exchange1.png`} alt="" />
          </div>
          <div className="service__shape-2 d-none d-xl-block">
            <img src={`assets/images/exchange2.png`} alt="" />
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-lg-9">
                <div className="service__section-box text-center exchange-feature mb-50">
                  <h4 className="section-subtitle">Dot-Exchange Features</h4>
                  {/* <h2 class="section-title">
                    One of the most innovative digital marketplace for crypto collectibles and non-fungible tokens (NFTs). NFT marketplace with an appealing and instinctive presentation that rundowns various NFTs like advanced expressions, music, in-game things, exchanging cards, and so forth
                    Buy, sell, & explore exclusive digital collectibles.
                  </h2> */}
                </div>
              </div>
            </div>
            <div className="row">
            <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg exchange text-center">
                  <div className="service__top-icon exchange1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Trade as per your convenience</a></h4>
                      <p>The primary feature of the stage is an NFT wallet that empowers clients to make consistent transactions to send, get and store NFTs. You name the kind of wallet and we will integrate it for you.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg exchange text-center">
                  <div className="service__top-icon exchange1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Immediate deposits and withdrawals</a></h4>
                      <p>Dot-Exchange is upgraded frequently and ensures a secure and rapid trading environment. Transfer coins to and from the platform in no time.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg exchange text-center">
                  <div className="service__top-icon exchange1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Strong Data Encryption</a></h4>
                      <p>Your transaction information is secured via end-to-end encryption, guaranteeing that only you have access to your information.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg exchange text-center">
                  <div className="service__top-icon exchange1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>Strong <br></br>Community</a></h4>
                      <p>The Dot-Exchange crypto Community is home to a humongous number of users from different countries across the globe.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-6 col-md-6 mb-30">
                <div className="service__item service__item-bg-none service__item-inner-bg exchange text-center">
                  <div className="service__top-icon exchange1">
                    <span><img src={`assets/images/customer-service.png`} alt="purchase-icon1" /></span>
                    <div className="service__content z-index">
                      <h4 className="service__title-sm"><a>24/7 Customer Support</a></h4>
                      <p>Contact Dot-Exchange customer support with your questions and get rapid solutions for your concerns.</p>
                    </div>
                    {/* <div class="service__icon-2 z-index">
                      <a href="#">Read More <i class="far fa-arrow-right"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
           
            </div>
          </div>
        </div>
        {/* loaction-page start */}
        <section id="roadmaps-exchange">
          <div className="loaction-page">
            <div className="container-flude">
              <h2>Road Map</h2>
              <ul className="timeline list-unstyled">
                {/* History Year */}
                <li className="year"><img src={`assets/images/locator.png`} /> </li>
                <li className="note animated fadeInRight visible road1" data-animation="fadeInRight" data-animation-delay={50}>
                  <h4>
                    DotCoin Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road2" data-animation="fadeInLeft" data-animation-delay={50}>
                  <h4>
                    Quarter 3, 2022
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road3" data-animation="fadeInRight" data-animation-delay={100}>
                  <h4>
                    Quarter 4, 2022 </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road4" data-animation="fadeInLeft" data-animation-delay={100}>
                  <h4>
                    DotPay Payment Processor
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                {/* History Year */}
                <li className="year"><img src={`assets/images/locator.png`} /></li>
                <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Quarter 1, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Dot NFT Marketplace Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road7" data-animation="fadeInRight" data-animation-delay={150}>
                  <h4>
                    Dot Trading Bot Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInRight visible road8" data-animation="fadeInRight" data-animation-delay={150}>
                  <h4>
                    Quarter 2, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road5" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    Quarter 3, 2023
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                <li className="note animated fadeInLeft visible road6" data-animation="fadeInLeft" data-animation-delay={150}>
                  <h4>
                    DotExchange Launch
                  </h4>
                  <span className="arrow fa fa-play" />
                </li>
                {/* Start icon */}
                <li className="year last"><img src={`assets/images/locator.png`} /></li>
                <li className="clear" />
              </ul>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default DotExchange;
