import React from 'react'
import {Footer,Navbar} from '../common'
import {Contact,Dotcoin,HowToPurchase,Roadmap,Product} from '../content'


const Home = () => { console.log("we are home")
  return (
    <React.Fragment>
    <Navbar/>
    <Dotcoin/>
    <HowToPurchase/>
    <Roadmap/>
    <Product/>
    <Contact/>
    <Footer/>
        

    </React.Fragment>
  )
}

export default Home