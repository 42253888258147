import React, { useState } from "react";
// import $ from "jquery";
// import "jquery-ui-dist/jquery-ui";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();
    
    if (name != "" && email != "" && phone != "" && company != "" && message != "" ) 
    { 
      var data = {
        "webservice_type": "inquiry",
        "summary_work_required": "This is a lead from dotcoin",
        "message": message,
        "source_website": "https://www.dotsquares.com/lp/hubspot-partner-agency/",
        "source": "dotcoin.thedotverse.com",
        "search_url": "https://dotcoin.thedotverse.com",
        "search_keywords": "",
        "quote_for": "",
        "phone": phone,
        "lead_status": "Lead",
        "lead_quality": "M",
        "lead_name": "Lead from dotcoin.thedotverse.com website by " + name,
        "gdpr_offers_and_events": 1,
        "gdpr_marketing": 1,
        "gdpr_longitude": "77.2167",
        "gdpr_latitude": "28.6667",
        "gdpr_ip": "",
        "gdpr_important_technical_updates": 1,
        "gdpr_all": 1,
        "email": email,
        "detail_specification": "",
        "country": "",
        "contact_name": name,
        "company_name": company,
        "company_address": "",
        "referral_url": "",
        "technology":"blockchain"
    };
      try {
        setLoading(true);
       // fetch("https://uatdsc.dotsquares.com/dscwebservices/Webhookgeneral.php", {
          fetch("https://dscwebservices.dotsquares.com/Webhookgeneral.php", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }).then((response) => {
            console.log(response);
            toast.success("Message send successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            setName("");
            setEmail("");
            setPhone("");
            setCompany("");
            setMessage("");
            setLoading(false);
        })
      }
      catch(error) {
        console.log(error);
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);
      }
    } else {
      toast.error("All fields are mandatory!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
     //console.log("All fields are mandatory!");
    }
  }

  return (
    <React.Fragment>
      <div className="home-contact-form">
        <div className="container-flude">
          <section className="get-in-touch">
            <h2 className="title">Contact</h2>
            <p>Have questions or looking to collaborate?</p>
            <form className="contact-form row" id="contact-form" onSubmit={handleSubmit}>
              <div className="formMsg" />
              <div className="form-field col-lg-6">
                <input id="name" name="name" className="input-text js-input" type="text" value={name} onChange={(e) => setName(e.target.value)}
                  required />
                <label className="label" htmlFor="name"> Name </label>
              </div>
              <div className="form-field col-lg-6 ">
                <input name="email" id="email" className="input-text js-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                  required/>
                <label className="label" htmlFor="email"> E-mail </label>
              </div>
              <div className="form-field col-lg-6 ">
                <input id="company" name="company" className="input-text js-input" type="text" value={company} onChange={(e) => setCompany(e.target.value)}
                  required/>
                <label className="label" htmlFor="company"> Company Name </label>
              </div>
              <div className="form-field col-lg-6 ">
                <input name="phone" id="phone" className="input-text js-input" type="number" value={phone} onChange={(e) => setPhone(e.target.value)}
                  required/>
                <label className="label" htmlFor="phone"> Contact Number </label>
              </div>
              <div className="form-field col-lg-12">
                <input name="message" id="message" className="input-text js-input" type="text" value={message} onChange={(e) => setMessage(e.target.value)}
                  required/>
                <label className="label" htmlFor="message"> Message </label>
              </div>
              <div className="form-field col-lg-6">
                <div id="captcha" style={{ float: "left" }}></div>
              </div>
              {/* <p id="captchaError" style="display: none;"></p> */}

              {loading ? 
                    <div className='col-12'>
                    <div className="loadingWrapper">
                            <div className="loading" align="center">
                                <img  src={`assets/images/loading.gif`} className="loding-image" alt="" />
                            </div>
                        </div>
                    </div>
                    :
              <div className="form-field col-lg-12">
                <button className="submit-btn" type="submit"> Sumbit </button>
              </div>
            }
              <ToastContainer />
            </form>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
